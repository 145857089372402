<template>
  <div>
    <QueryForm
      v-model="queryForm"
      show-export
      :query-btn-loading="tableLoading"
      :rules="rules"
      @handleExport="handleExport"
      @query="handleQuery"
      @reset="handleQueryFormReset"
    >
      <el-form-item label="库存日期" prop="invDate">
        <el-date-picker
          v-model="queryForm.invDate"
          value-format="yyyy-MM-dd"
          type="date"
          :picker-options="pickerOptions"
        />
      </el-form-item>
      <el-form-item label="仓库">
        <Select
          v-model="queryForm.physicalWarehouseCodeList"
          api-key="getPhysicsWarehouseList"
          clearable
          multiple
          :configuration="{
            key: 'logicWarehouseCode',
            label: 'warehouseName',
            value: 'logicWarehouseCode'
          }"
        />
      </el-form-item>
      <el-form-item label="备货渠道">
        <Select
          v-model="queryForm.channelIdList"
          api-key="StockChannelList"
          clearable
          :configuration=" {
            key: 'channelId',
            label: 'channelName',
            value: 'channelId'
          }"
          multiple
        />

      </el-form-item>
      <el-form-item label="产品类目">
        <el-cascader
          v-model="queryForm.productCategoryIdList"
          clearable
          collapse-tags
          filterable
          separator=">"
          :options="productcategorydatas"
          class="style_w100"
          :props="{
            value: 'id',
            label: 'categoryCnName',
            children: 'childList',
            multiple: true,
          }"
        />

      </el-form-item>
      <el-form-item label="商品类目">
        <el-cascader
          v-model="queryForm.categoryIdList"
          clearable
          collapse-tags
          filterable
          separator=">"
          :options="basecategorydatas"
          class="style_w100"
          :props="{
            value: 'id',
            label: 'categoryName',
            children: 'sonCategory',
            multiple: true,
          }"
        />
      </el-form-item>
      <el-form-item label="产品定位">
        <Select
          v-model="queryForm.positionIdList"
          style="width:100%;"
          api-key="position"
          :configuration="{
            key: 'stylePositionId',
            label: 'position',
            value: 'stylePositionId'
          }"
          clearable
          filterable
          collapse-tags
          multiple
        />
      </el-form-item>
      <el-form-item label="品牌">
        <Select
          v-model="queryForm.brandNameList"
          api-key="brandList"
          clearable
          :configuration=" {
            key: 'id',
            label: 'brandName',
            value: 'brandName'
          }"
          multiple
        />
      </el-form-item>

      <el-form-item label="BG">
        <Select
          v-model="queryForm.bgList"
          api-key="brandList"
          clearable
          multiple
          :configuration=" {
            key: 'id',
            label: 'brandName',
            value: 'brandName'
          }"
        />
      </el-form-item>
      <el-form-item label="BU">
        <Select
          v-model="queryForm.buNameList"
          api-key="buList"
          clearable
          multiple
          :configuration="{
            key: 'id',
            label: 'buName',
            value: 'buName'
          }"
        />
      </el-form-item>
      <el-form-item label="适用季节">
        <Select
          v-model="queryForm.seasonIdList"
          :select-options="_getAllCommodityDict('SEASON')"
          :configuration=" { key: 'val', label: 'label', value: 'val' }"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="上市季节">
        <Select
          v-model="queryForm.launchSeasonDictKeyList"
          api-key="listLaunchSeason"
          clearable
          multiple
        />
      </el-form-item>

      <el-form-item label="Style">
        <Select
          v-model="queryForm.styleId"
          api-key="styleList"
          clearable
          @change="handleChangeStyle"
          @responseData="val=>styleList=val"
        />
      </el-form-item>
      <el-form-item label="Color">
        <Select
          v-model="queryForm.colorName"
          api-key="colorList"
          :params="{ styleId : queryForm.styleId }"
          ganged
          :configuration=" { key: 'id', label: 'colorName', value: 'colorName' }"
          not-required-params
        />
      </el-form-item>
      <el-form-item label="Size">
        <Select
          v-model="queryForm.sizeName"
          not-required-params
          api-key="sizeList"
          :params="{ styleId : queryForm.styleId}"
          ganged
          :configuration=" { key: 'id', label: 'size', value: 'size' }"
        />
      </el-form-item>
      <el-form-item label="SKU">
        <el-input v-model="queryForm.sku" :placeholder="$t('page.inputPlaceholder')" />
      </el-form-item>
      <el-form-item label="数据版本">
        <el-select
          v-model="queryForm.invVersion"
          filterable
          :placeholder="$t('page.selectPlaceholder')"
          @change="val=>handleInvDate(val)"
        >
          <el-option
            v-for="item in dsbVersionOptions"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>

      </el-form-item>

    </QueryForm>

    <Table
      :table-data="tableData"
      max-height="600px"
      :columns="columns.inventoryStaticsTableColumns"
      :loading="tableLoading"
    />
    <Paging :pager="pager" end class="mt-3" @pagination="pagerUpdate" />

  </div>
</template>

<script>
import QueryForm from '@/components/QueryForm'
import { statsInventoryPageList, getBasecategory, exportInventory, inventoryVersion } from '@/api/configuration'
import Table from '@/components/Table'
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Paging from '@/components/Pagination'
import columns from '@/constant/tablecolumns'
import { productCategoryApi } from '@/api/listSelection'
import { handlePageParams } from '@/utils'

export default {
  components: { QueryForm, Table, Select, Paging },
  mixins: [commodityInfoDict],
  data() {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < new Date(this.queryForm.invDefaultDate).getTime() - 3600 * 1000 * 24
        }
      },
      rules: {
        invDate: { required: true, message: '必填' }
      },
      columns,
      tableData: [],
      queryForm: {
        styleId: '',
        colorName: '',
        sizeName: '',
        categoryIdList: [],
        productCategoryIdList: [],
        invDate: '',
        invVersion: ''

      },
      tableLoading: false,
      basecategorydatas: [],
      styleList: [],
      pager: {
        size: 10,
        current: 1
      },
      dsbVersionOptions: [],
      productcategorydatas: []
    }
  },
  computed: {
    qeuryParams() {
      return handlePageParams({
        formData: this.queryForm,
        processedParameter: {

          lastCategoryIdList: ['categoryIdList', 'productCategoryIdList'],
          findNameOrIdList: {
            styleName: ['styleId', 'id', 'styleName', this.styleList]
          }
        }

      })
    }

  },
  watch: {
    dsbVersionOptions(value) {
      if (value && value.length && this.queryForm.invDate) {
        this.handleQuery()
      }
    }
  },

  mounted() {
    this._getBasecategory()
    this._getProductCategory()
    this._getDsbVersionOptions()
  },
  methods: {
    handleInvDate(date) {
      const year = date.slice(0, 4)
      const month = date.slice(5)
      // 添加横线和日期
      this.queryForm.invDate = year + '-' + month + '-01'
      this.queryForm.invDefaultDate = year + '-' + month + '-01'
    },
    async _getDsbVersionOptions() {
      const { datas } = await inventoryVersion()
      this.dsbVersionOptions = datas
      this.queryForm.invVersion = datas[0]
      this.handleInvDate(datas[0])
    },
    async handleExport() {
      await exportInventory(this.qeuryParams)
      this.$notify({
        message: '操作成功，请前往文件导出界面下载',
        type: 'success'
      })
    },
    async _getBasecategory() {
      const { datas } = await getBasecategory()
      this.basecategorydatas = datas
    },
    async _getProductCategory() {
      const { datas } = await productCategoryApi()
      this.productcategorydatas = datas
    },
    pagerUpdate(val) {
      this.pager = val
      this.handleClick()
    },
    handleChangeStyle() {
      this.queryForm.colorName = ''
      this.queryForm.sizeName = ''
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.queryForm.invVersion = this.dsbVersionOptions[0]
      this.handleInvDate(this.queryForm.invVersion)
      this.handleQuery()
    },
    handleQuery() {
      this.pager.current = 1
      this.handleClick()
    },
    async handleClick() {
      try {
        this.tableLoading = true
        const { datas: { records = [], current, size, total }} = await statsInventoryPageList(this.pager, this.qeuryParams)
        this.tableData = records
        this.pager = { current, size, total }
      } finally {
        this.tableLoading = false
      }
    }

  }
}
</script>

<style>

</style>
